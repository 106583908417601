import * as React from 'react';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import styled, { useTheme } from 'styled-components';
import { ThemeProvider } from '@mui/material';
import { useEffect, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { CustomButton } from '../Button';
import { t } from 'i18next';
import { originToColor, GENERIC } from '../../constants';
import { translations } from 'locales/translations';
import {
  StyledHeadCell,
  StyledTableCell,
  SlimButton,
  Chip,
  StyledTableRow,
} from './styles';
import { Table } from '@mui/material';
import { AcceptRejectValueModal } from '../AcceptRejectValueModal';

interface MyPositionTableProps {
  coffees: any[];
  requestList: any[];
  setRequestList: Function;
  getCoffeesWithFiltersFirstCall: () => void;
}

const Warehouse = styled.div`
  display: none;
  background: #1989b2;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  width: 201px;
  height: 49px;
  margin-left: 14px;
  font-weight: 325;
  font-size: 15px;
  overflow: hidden;
  text-align: center;
`;

export function MyPositionTablesPreShipment({
  coffees,
  requestList,
  setRequestList,
  getCoffeesWithFiltersFirstCall,
}: MyPositionTableProps) {
  const theme: any = useTheme();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<number>(0);
  const [id, setId] = useState('');
  const [type, setType] = useState('Confirm');
  const [coffesGrouped, setCoffes] = useState(false);

  const yes = t(translations.general.sample_accepted);
  const no = t(translations.general.sample_rejected);

  const formatCoffe = coffe => {
    let groupingViaCommonProperty = Object.values(
      coffe.reduce((acc, current) => {
        acc[current.origin] = acc[current.origin] ?? [];
        acc[current.origin].push(current);
        return acc;
      }, {}),
    );
    let auxArray;
    let coffesFinal: any = [];
    for (let i = 0; i < groupingViaCommonProperty.length; i++) {
      let arr: any = groupingViaCommonProperty[i];
      auxArray = Object.values(
        arr.reduce((acc, current) => {
          acc[current.warehouse] = acc[current.warehouse] ?? [];
          acc[current.warehouse].push(current);
          return acc;
        }, {}),
      );
      const finalArray = auxArray[0][0];
      for (let j = 0; j < finalArray.length; j++) {
        let internalArray: any = finalArray[j];
        finalArray[j] = internalArray.sort((a, b) => {
          if (a.received == 0 && b.received != 0) return 1;
          if (b.received == 0 && a.received != 0) return -1;

          if (a.received == null && b.received != null) return -1;
          if (b.received == null && a.received != null) return 1;

          if (a.received == 1 && b.received == 1) {
            // both are accepted
            if (a.result == null && b.result != null) return -1;
            if (b.result == null && a.result != null) return 1;

            if (a.result == 1 && b.result != 1) return -1;
            if (b.result == 1 && a.result != 1) return 1;
          }

          return 0;
        });
      }
      coffesFinal.push(finalArray);
    }
    setCoffes(coffesFinal);
  };

  const openModalNumeric = id => {
    setId(id);
    setType('Numeric');
    setOpen(true);
  };

  const confirmDenie = (value, id) => {
    setId(id);
    setValue(value);
    setType(value);
    setOpen(true);
  };

  function customSort(arr) {
    return arr.sort((a, b) => {
      if (a.received === null && b.received !== null) {
        return -1;
      } else if (a.received !== null && b.received === null) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  const sortedData = coffees.map(arr => customSort(arr));

  return coffees.length > 0 ? (
    <>
      <ThemeProvider theme={theme}>
        {open === true ? (
          <AcceptRejectValueModal
            setOpen={setOpen}
            type={type}
            id={id}
            value={value}
            getCoffeesWithFiltersFirstCall={getCoffeesWithFiltersFirstCall}
          />
        ) : (
          sortedData.map(group => (
            <>
              <TableContainer>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead sx={{ width: '100%' }}>
                    <StyledTableRow
                      sx={{
                        width: '100%',
                        '& th': {
                          color: theme.palette.secondary.contrastText,
                          backgroundColor: theme.palette.secondary.main,
                        },
                      }}
                    >
                      <StyledHeadCell align="center">
                        {t(translations.table.ico)}
                      </StyledHeadCell>
                      <StyledHeadCell align="center">
                        {t(translations.table.identifier)}
                      </StyledHeadCell>
                      <StyledHeadCell align="center">
                        {t(translations.table.origin)}
                      </StyledHeadCell>
                      <StyledHeadCell align="center">
                        {t(translations.table.marks)}
                      </StyledHeadCell>
                      <StyledHeadCell align="center">
                        {t(translations.table.type_sample)}
                      </StyledHeadCell>
                      <StyledHeadCell
                        align="center"
                        style={{
                          color: theme.palette.primary.contrastText,
                          background: theme.palette.primary.main,
                        }}
                      >
                        {t(translations.general.status)}
                      </StyledHeadCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {sortedData.map(subitem =>
                      subitem.map(
                        groupBy =>
                          groupBy.warehouse === group[0].warehouse && (
                            <StyledTableRow key={groupBy.contract}>
                              <StyledTableCell align="center">
                                {groupBy.ico}
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                {groupBy.sample_type === 'Sample Arrival'
                                  ? groupBy.local_identifier
                                  : groupBy.main_identifier}
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                <div
                                  style={{
                                    borderRadius: '30px',
                                    background:
                                      originToColor[
                                        groupBy.origin.toUpperCase()
                                      ] || GENERIC,
                                    color: 'white',
                                    padding: '15px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '40px',
                                    width: '160px',
                                  }}
                                >
                                  {groupBy.origin}
                                </div>
                              </StyledTableCell>

                              <StyledTableCell
                                align="center"
                                style={{
                                  textOverflow: 'ellipsis',
                                  display: groupBy.broshure
                                    ? 'flex'
                                    : 'table-cell',
                                  justifyContent: 'center',
                                }}
                              >
                                {groupBy.broshure ? (
                                  <a
                                    href={groupBy.broshure}
                                    style={{
                                      textDecoration: 'none',
                                      borderRadius: '30px',
                                      background: theme.palette.primary.main,
                                      color: theme.palette.primary.contrastText,
                                      padding: '15px',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      height: '40px',
                                      width: '160px',
                                    }}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {groupBy.mark}
                                  </a>
                                ) : (
                                  groupBy.mark
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {groupBy.sample_type}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {groupBy.result === null && (
                                  <>
                                    <SlimButton
                                      onClick={() =>
                                        confirmDenie('Confirm', groupBy.id)
                                      }
                                      background={theme.palette.success.main}
                                      color={theme.WhitePowder}
                                    >
                                      {yes}
                                    </SlimButton>
                                    <SlimButton
                                      onClick={() =>
                                        confirmDenie('Reject', groupBy.id)
                                      }
                                      background={theme.palette.error.main}
                                      color={theme.WhitePowder}
                                    >
                                      {no}
                                    </SlimButton>
                                  </>
                                )}
                                {groupBy.result !== null &&
                                  groupBy.result !== '' && (
                                    <SlimButton
                                      background={
                                        groupBy.result === 0 ||
                                        groupBy.result === '0'
                                          ? theme.palette.error.main
                                          : theme.palette.success.main
                                      }
                                      color={theme.WhitePowder}
                                      style={{
                                        cursor: 'default',
                                      }}
                                    >
                                      {groupBy.result === 0 ||
                                      groupBy.result === '0'
                                        ? t<string>(
                                            translations.general.declined,
                                          )
                                        : t<string>(
                                            translations.general.accepted,
                                          )}
                                    </SlimButton>
                                  )}
                              </StyledTableCell>
                            </StyledTableRow>
                          ),
                      ),
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ))
        )}
      </ThemeProvider>
    </>
  ) : null;
}
