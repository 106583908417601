import styled, { useTheme } from 'styled-components/macro';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import axios from 'axios';
import { setAcceptedOrRejected, setRecieved } from 'services/sampleServices';
import { TextField, useMediaQuery } from '@mui/material';
import TextArea from '../TextArea';
import Select from 'react-select';
import i18next from 'i18next';

interface AcceptRejectValueProps {
  setOpen: (value: boolean) => void;
  type: string;
  id?: string;
  value?: number;
  getCoffeesWithFiltersFirstCall: () => void;
}

const Button = styled.input`
  border: none;
  background: #5c36f3;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin-bottom: 25px;
  width: 213.02px;
  height: 49.59px;
  margin: 0 10px;
`;
const Bg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(15px);
`;

const Login = styled.div`
  width: 518px;
  //height: 298px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  border-radius: 30px;
  z-index: 1000;
`;

const InputPass = styled.input`
  width: 166.84px;
  height: 48.71px;
  left: 519.78px;
  top: 391.99px;
  background: lightGrey;
  border-radius: 30px;
  border: 0;
  padding: 0 20px;
  text-align: center;
`;

const InputText = styled.div`
  font-style: normal;
  font-weight: 350;
  font-size: 20px;
  line-height: 24px;
  color: #545454;
  margin: 10px 0;
  padding: 0 50px;
`;

export function AcceptRejectValueModal({
  setOpen,
  type,
  id,
  value,
  getCoffeesWithFiltersFirstCall,
}: AcceptRejectValueProps) {
  const { t } = useTranslation();
  const theme: any = useTheme();

  const isMobile = useMediaQuery('(max-width:767px)');

  const closeModal = () => {
    setOpen(false);
  };

  const sentReceived = () => {
    const payload = {
      id: id,
      received: value,
    };
    setRecieved(payload)
      .then(response => {
        if (response.status === 200) {
          getCoffeesWithFiltersFirstCall();
          setOpen(false);
          setError('');
        }
      })
      .catch(error => {
        setError(t(translations.general.error_something));
      });
  };

  const sentAccepted = () => {
    const numericValue = parseFloat(new_value);
    if (isNaN(numericValue) || numericValue <= 0) {
      setError(t(translations.general.value_higher_0));
    } else {
      const payload = {
        id: id,
        score: numericValue,
        notes: description,
      };
      setAcceptedOrRejected(payload)
        .then(response => {
          if (response.status === 200) {
            getCoffeesWithFiltersFirstCall();
            setOpen(false);
            setError('');
          }
        })
        .catch(error => {
          setError(t(translations.general.error_something));
        });
    }
  };

  const sentRejected = () => {
    const payload = {
      id: id,
      reason: reason_option_id,
      notes: description,
    };
    if (reason_option_id === 0) {
      setError('Need to write a reason of rejected');
    } else {
      setAcceptedOrRejected(payload)
        .then(response => {
          if (response.status === 200) {
            getCoffeesWithFiltersFirstCall();
            setOpen(false);
            setError('');
          }
        })
        .catch(error => {
          setError(t(translations.general.error_something));
        });
    }
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closeModal();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const [error, setError] = useState('');
  const [new_value, setNewV] = useState<any>(0);
  const [success, setSuccess] = useState('');
  const [reject, setReject] = useState('');
  const [description, setDescription] = useState('');
  const [moduleList, setModules] = useState([] as Array<any>);
  const [reason_option_id, setReasonID] = useState<number>(0);

  const setNewValue = e => {
    setNewV(e.target.value);
  };

  const setReason = e => {
    setReject(e.target.value);
  };

  const setDescriptionValue = e => {
    setDescription(e.target.value);
  };

  const handleReasonChange = (e: any) => {
    setReasonID(e.value);
  };

  useEffect(() => {
    const currentLanguage = i18next.language;
    const languageCode =
      currentLanguage.length > 2
        ? currentLanguage.substr(0, 2)
        : currentLanguage;

    let moduleUrl = process.env.REACT_APP_BASE_URL + '/rejected-reasons';

    const token = localStorage.getItem('caravela_access_token');

    axios
      .get(moduleUrl, {
        headers: {
          'Accept-Language': languageCode,
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => setModules(response.data))
      .catch(error => {
        setModules([error.response?.data?.message]);
      });
  }, [i18next.language]);

  return (
    <Bg>
      <Login
        ref={wrapperRef}
        style={{
          width: !isMobile ? '518px' : '300px',
          // height: !isMobile ? '298px' : '200px',
        }}
      >
        {type === 'Confirm' ? (
          <div className="d-flex flex-column">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <InputText
                style={{
                  color: 'black',
                  marginTop: !isMobile ? '70px' : '20px',
                  padding: !isMobile ? '0' : '0 90px ',
                }}
              >
                {t(translations.general.submit_score)}
              </InputText>
            </div>

            <div className="d-flex justify-content-center mt-2">
              <InputPass onChange={e => setNewValue(e)} type="number" />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <InputText
                style={{
                  color: 'black',
                  marginTop: !isMobile ? '30px' : '20px',
                  padding: !isMobile ? '0' : '0 90px ',
                }}
              >
                {t(translations.general.cupping_notes)}
              </InputText>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <TextArea
                style={{
                  width: !isMobile ? '420px' : '250px',
                  height: '150px',
                }}
                onChange={e => setDescriptionValue(e)}
              />
            </div>
            {error !== '' ? (
              <div
                style={{
                  color: 'red',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {error}
              </div>
            ) : null}
            <div
              style={{
                display: 'flex',
                flexDirection: !isMobile ? 'row' : 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '30px',
              }}
            >
              {success === '' && (
                <Button
                  style={{
                    marginTop: !isMobile ? '35px' : '20px',
                    background: theme.palette.success.main,
                  }}
                  type="button"
                  onClick={sentAccepted}
                  value={t(translations.general.accept)}
                />
              )}

              <Button
                style={{
                  marginTop: !isMobile ? '35px' : '10px',
                  background:
                    success === ''
                      ? theme.palette.error.main
                      : theme.success.primary.main,
                }}
                type="button"
                onClick={closeModal}
                value={
                  success === ''
                    ? t(translations.general.cancel)
                    : t(translations.general.close)
                }
              />
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <InputText
                style={{
                  color: 'black',
                  marginTop: !isMobile ? '70px' : '20px',
                  padding: !isMobile ? '0' : '0 90px ',
                }}
              >
                {t(translations.general.rejected_reason)}
              </InputText>
            </div>
            <Select
              isSearchable={false}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  background: '#F6F6F6',
                  borderRadius: '30px',
                  width: '100%',
                  height: '50px',
                }),
              }}
              options={moduleList.map(option => ({
                label: option.reason,
                value: option.id,
              }))}
              defaultValue={{ label: '', value: 0 }}
              onChange={e => handleReasonChange(e)}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <InputText
                style={{
                  color: 'black',
                  marginTop: !isMobile ? '30px' : '20px',
                  padding: !isMobile ? '0' : '0 90px ',
                }}
              >
                {t(translations.general.rejected_observation)}
              </InputText>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <TextArea
                style={{
                  width: !isMobile ? '420px' : '250px',
                  height: '150px',
                }}
                onChange={e => setDescriptionValue(e)}
              />
            </div>
            {error !== '' ? (
              <div
                style={{
                  color: 'red',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {error}
              </div>
            ) : null}
            <div
              style={{
                display: 'flex',
                flexDirection: !isMobile ? 'row' : 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '30px',
              }}
            >
              {success === '' && (
                <Button
                  style={{
                    marginTop: !isMobile ? '35px' : '20px',
                    background: theme.palette.success.main,
                  }}
                  type="button"
                  onClick={sentRejected}
                  value={t(translations.general.accept)}
                />
              )}

              <Button
                style={{
                  marginTop: !isMobile ? '35px' : '10px',
                  background:
                    success === ''
                      ? theme.palette.error.main
                      : theme.palette.success.main,
                }}
                type="button"
                onClick={closeModal}
                value={
                  success === ''
                    ? t(translations.general.cancel)
                    : t(translations.general.close)
                }
              />
            </div>
          </div>
        )}
      </Login>
    </Bg>
  );
}
