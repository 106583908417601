import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { height, width } from '@mui/system';
import { UploadLogoModal } from 'app/components/UploadLogoModal';
import { useMediaQuery } from '@mui/material';
import Dropdown from 'app/components/Dropdown';
import { getDeliveryAddress } from 'services/purchaseService';
import styled, { useTheme } from 'styled-components';

const Input = styled.textarea`
  width: 50vh;
  height: 140.71px;
  left: 519.78px;
  top: 391.99px;
  background: #ebeaea;
  border-radius: 30px;
  border: 0;
  padding: 10px 20px;
  @media (max-width: 767px) {
    width: 30vh;
  }
`;

const Button = styled.input<{ backgroundColor?: string }>`
  border: none;
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : '#5c36f3'};
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin-bottom: 25px;
  width: 213.02px;
  height: 49.59px;
  margin: 0 10px;
`;

const Container = styled.div`
  display: flex;
  @media (max-width: 1568px) {
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
`;

const InputText = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  @media (max-width: 1568px) {
    align-items: flex-start;
  }
`;

const Inside = styled.div`
  @media (max-width: 1568px) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

const ShortInput = styled.input`
  width: 50vh;
  height: 42px;
  left: 519.78px;
  top: 391.99px;
  background: #ebeaea;
  border-radius: 30px;
  border: 0;
  padding: 10px 20px;
  @media (max-width: 767px) {
    width: 30vh;
  }
`;

const Checkbox = styled.input`
  width: 30px;
  height: 30px;
`;

const Text = styled.div`
  font-size: 18px;
  font-weight: 425;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 5px;
  padding: 10px;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45vh;
  height: 55vh;
  // left: 519.78px;
  // top: 391.99px;
  background: #f9f9f9;
  border-radius: 30px;
  border: 0;
  margin-top: 80px;
  padding: 10px 20px;
  @media (max-width: 768px) {
    width: 90%;
  }
  @supports (-webkit-touch-callout: none) {
    /* Estilos específicos para Safari en iOS */
    input[type='date'] {
      font-size: 16px; /* Ajusta el tamaño de fuente del input */
    }
  }
`;

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const Tooltip = styled.div<{ show: boolean }>`
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -100px;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.3s;
`;

const InfoButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: none;
  background-color: #4f4e24;
  color: white;
  font-size: 18px;
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
`;

const currentDate = new Date();
currentDate.setDate(currentDate.getDate() + 2);
const twoDaysLater = currentDate.toISOString().split('T')[0];

interface ThirProps {
  handleChangeUK: (value: any) => void;
  infoUK: any;
  setInfoUK: (value: any) => void;
  handleTextDateUK: (value: any, date: any) => void;
  handleChange: (e: any, value: any) => void;
  setInfoOther: (value: any) => void;
  infoOther: any;
  handleTextDate: (value: any, e: any) => void;
  setNeedDetail: (value: any) => void;
  userData: any;
  newAddress: boolean;
  setNewAddress: (value: boolean) => void;
}

interface Address {
  id: number;
  label: string;
  value: string;
}

export function ThirdStep({
  infoUK,
  setInfoUK,
  infoOther,
  setInfoOther,
  handleChangeUK,
  handleChange,
  handleTextDateUK,
  handleTextDate,
  userData,
  setNeedDetail,
  newAddress,
  setNewAddress,
}: ThirProps) {
  const { t } = useTranslation();

  const [showTooltip1, setShowTooltip1] = useState(false);
  const [showTooltip2, setShowTooltip2] = useState(false);
  const [showTooltip3, setShowTooltip3] = useState(false);
  const [showTooltip4, setShowTooltip4] = useState(false);

  const openCloseModal = () => {
    setOpen(!open);
  };
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState('empty');
  const [selection, setSelection] = useState('none');
  const theme: any = useTheme();
  const handle = (id, name) => {
    name === 'OTHER-3' ? setNeedDetail(false) : setNeedDetail(true);
    handleChange('OTHER-3', 'OTHER-3');
    selection === name ? setSelection('none') : setSelection(name);
  };

  const isMobile = useMediaQuery('(max-width:767px)');

  const [addresses, setAddresses] = useState([]);

  const addNewAddress = () => {
    setNewAddress(!newAddress);
    handleTextDateUK('', 'address');
  };

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await getDeliveryAddress();
        setAddresses(
          response.data.map(address => ({
            value: address.id,
            label: address.address,
          })),
        );
      } catch (error) {
        console.error('Error with directions:', error);
      }
    };
    fetchAddresses();
  }, []);

  return (
    <>
      <Helmet>
        <title>Purchase Order</title>
        <meta name="description" content="My Roasterhub order" />
      </Helmet>
      <Container>
        {userData[0].importer_country !== 'United Kingdom' ? (
          <Inside
            className="d-flex flex-column "
            style={{ alignItems: 'flex-start' }}
          >
            <h1>{t(translations.form.option)}</h1>
            <div className="d-flex align-items-center m-3">
              <div>
                <Checkbox
                  type="checkbox"
                  id="OTHER-1"
                  name="OTHER-1"
                  value="Paneer"
                  disabled={
                    infoOther[1].value === false &&
                    infoOther[6].value === false &&
                    infoOther[7].value === false
                      ? false
                      : true
                  }
                  onChange={e => handleChange(e.target.id, e.target.name)}
                />
              </div>
              <div>
                <Text>{t(translations.form.transfer)}</Text>
              </div>
              <TooltipContainer>
                <InfoButton
                  onMouseEnter={() => setShowTooltip1(true)}
                  onMouseLeave={() => setShowTooltip1(false)}
                >
                  i
                </InfoButton>
                <Tooltip show={showTooltip1}>
                  {t(
                    translations.pages.purchase_order.tooltipRelease
                      .transferMyPosition,
                  )}
                </Tooltip>
              </TooltipContainer>
            </div>
            <div className="d-flex align-items-center m-3">
              <div>
                <Checkbox
                  type="checkbox"
                  id="OTHER-2"
                  name="OTHER-2"
                  value="Paneer"
                  disabled={
                    infoOther[0].value === false &&
                    infoOther[6].value === false &&
                    infoOther[7].value === false
                      ? false
                      : true
                  }
                  onChange={e => handleChange(e.target.id, e.target.name)}
                />
              </div>
              <div>
                <Text>{t(translations.form.other_importer)}</Text>
              </div>
              <TooltipContainer>
                <InfoButton
                  onMouseEnter={() => setShowTooltip2(true)}
                  onMouseLeave={() => setShowTooltip2(false)}
                >
                  i
                </InfoButton>
                <Tooltip show={showTooltip2}>
                  {t(
                    translations.pages.purchase_order.tooltipRelease
                      .consolidate,
                  )}
                </Tooltip>
              </TooltipContainer>
            </div>
            <div className="d-flex align-items-center m-3">
              <Checkbox
                type="checkbox"
                id="OTHER-3C-Pickup"
                name="OTHER-3C-Pickup"
                value="Paneer"
                onChange={e => handleChange(e.target.id, e.target.name)}
                disabled={
                  infoOther[0].value === false &&
                  infoOther[1].value === false &&
                  infoOther[7].value === false
                    ? false
                    : true
                }
              />
              <Text>{t(translations.form.arrange3)}</Text>
              <TooltipContainer>
                <InfoButton
                  onMouseEnter={() => setShowTooltip3(true)}
                  onMouseLeave={() => setShowTooltip3(false)}
                >
                  i
                </InfoButton>
                <Tooltip show={showTooltip3}>
                  {t(translations.pages.purchase_order.tooltipRelease.pickUp)}
                </Tooltip>
              </TooltipContainer>
            </div>
            <div className="d-flex align-items-center m-3">
              <Checkbox
                type="checkbox"
                id="OTHER-3D-MyPosition"
                name="OTHER-3D-MyPosition"
                value="Paneer"
                onChange={e => handleChange(e.target.id, e.target.name)}
                disabled={
                  infoOther[0].value === false &&
                  infoOther[1].value === false &&
                  infoOther[6].value === false
                    ? false
                    : true
                }
              />
              <Text>{t(translations.form.arrangePosition)}</Text>
              <TooltipContainer>
                <InfoButton
                  onMouseEnter={() => setShowTooltip4(true)}
                  onMouseLeave={() => setShowTooltip4(false)}
                >
                  i
                </InfoButton>
                <Tooltip show={showTooltip4}>
                  {t(
                    translations.pages.purchase_order.tooltipRelease
                      .releaseMyPosition,
                  )}
                </Tooltip>
              </TooltipContainer>
            </div>
            <div className="d-flex flex-column align-items-start">
              <Text>{t(translations.form.what_importers)}</Text>
              <ShortInput
                onBlur={e => handleTextDate(e.target.value, 'IMPORTERS')}
                disabled={
                  infoOther[0].value === false &&
                  infoOther[6].value === false &&
                  infoOther[1].value === true
                    ? false
                    : true
                }
                style={{ margin: '10px' }}
                required
              />
              <Text>{t(translations.form.how_many)}</Text>
              <ShortInput
                onBlur={e => handleTextDate(e.target.value, 'BAGS')}
                disabled={
                  infoOther[0].value === false &&
                  infoOther[6].value === false &&
                  infoOther[1].value === true
                    ? false
                    : true
                }
                style={{ margin: '10px' }}
                required
              />
            </div>
            <div className="d-flex align-items-center m-3">
              <Checkbox
                type="checkbox"
                id="OTHER-3"
                name="OTHER-3"
                value="Paneer"
                onChange={e => handle(e.target.id, e.target.name)}
                disabled={
                  infoOther[1].value === true && infoOther[5].value === false
                    ? false
                    : infoOther[7].value === true &&
                      infoOther[5].value === false
                    ? false
                    : true
                }
              />
              <Text>{t(translations.form.arrange)}</Text>
            </div>
            <div className="d-flex align-items-center m-3">
              <Checkbox
                type="checkbox"
                id="OTHER-3BIS"
                name="OTHER-3BIS"
                value="Paneer"
                onChange={e => handleChange(e.target.id, e.target.name)}
                disabled={
                  infoOther[1].value === true && infoOther[4].value === false
                    ? false
                    : infoOther[7].value === true &&
                      infoOther[4].value === false
                    ? false
                    : true
                }
              />
              <Text>{t(translations.form.arrange2)}</Text>
            </div>
            <InputText>
              <Text>{t(translations.form.freight)}</Text>
              <ShortInput
                onBlur={e => handleTextDate(e.target.value, 'FREIGHT')}
                style={{ margin: '10px' }}
                disabled={
                  infoOther[0].value === false && selection !== 'OTHER-3'
                    ? false
                    : true
                }
                required
              />
            </InputText>
            <InputText>
              <Text>{t(translations.form.team)}</Text>
              <ShortInput
                onBlur={e => handleTextDate(e.target.value, 'MAIL')}
                style={{ margin: '10px' }}
                disabled={infoOther[0].value === false ? false : true}
                required
              />
            </InputText>
            <InputText>
              <Text>{t(translations.form.contact)}</Text>
              <ShortInput
                disabled={infoOther[0].value === false ? false : true}
                onBlur={e => handleTextDate(e.target.value, 'PHONE')}
                style={{ margin: '10px' }}
                required
              />
            </InputText>
            <div className="d-flex align-items-center m-3">
              <Checkbox
                type="checkbox"
                id="OTHER-4"
                name="OTHER-4"
                value="Paneer"
                disabled={infoOther[0].value === false ? false : true}
                onChange={e => handleChange(e.target.id, e.target.name)}
              />
              <Text>{t(translations.form.delivery)}</Text>
            </div>
            <div className="d-flex align-items-center m-3">
              <Checkbox
                type="checkbox"
                id="OTHER-5"
                name="OTHER-5"
                value="Paneer"
                disabled={infoOther[0].value === false ? false : true}
                onChange={e => handleChange(e.target.id, e.target.name)}
              />
              <Text>{t(translations.form.lift)}</Text>
            </div>
            <div className="d-flex align-items-center m-3">
              <Checkbox
                type="checkbox"
                id="OTHER-6"
                name="OTHER-6"
                value="Paneer"
                disabled={infoOther[0].value === false ? false : true}
                onChange={e => handleChange(e.target.id, e.target.name)}
              />
              <Text>{t(translations.form.credit_card)}</Text>
            </div>
            <div className="d-flex flex-column">
              <Text>{t(translations.form.carriers)}</Text>
              <ShortInput
                disabled={infoOther[0].value === false ? false : true}
                onBlur={e => handleTextDate(e.target.value, 'CARRIERS')}
                style={{ margin: '10px' }}
              />
            </div>
            <h4 className="mt-5">{t(translations.form.esp_instructions)} </h4>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: !isMobile ? 'flex-start' : 'center',
                marginTop: !isMobile ? '0px' : '20px',
              }}
            >
              <h4>{t(translations.form.info)}</h4>
              <Input
                style={{ marginBottom: '10px' }}
                onBlur={e => handleTextDate(e.target.value, 'Aditional')}
                // onChange={e => setInput(e.target.value)}
              />
            </div>
          </Inside>
        ) : null}

        {userData[0].importer_country === 'United Kingdom' ? (
          <Inside className="d-flex flex-column mt-3">
            <h1>To finalize your order we’ll need some information:</h1>
            <div className="d-flex align-items-center m-3">
              <div className="d-flex">
                <h2 style={{ paddingRight: '10px' }}> * </h2>
                <Checkbox
                  type="checkbox"
                  id="UK-1"
                  name="UK-1"
                  value="Paneer"
                  disabled={
                    infoUK[1].value === false && infoUK[2].value === false
                      ? false
                      : true
                  }
                  onChange={e => handleChangeUK(e.target.id)}
                />
              </div>
              <div>
                <Text>
                  Please transfer stock into my warehouse position (Stock
                  transfer / EXW).
                </Text>
              </div>
            </div>
            <div className="d-flex align-items-center m-3">
              <h2 style={{ paddingRight: '10px' }}> * </h2>
              <Checkbox
                type="checkbox"
                id="UK-2"
                name="UK-2"
                value="Paneer"
                disabled={
                  infoUK[0].value === false && infoUK[2].value === false
                    ? false
                    : true
                }
                onChange={e => handleChangeUK(e.target.id)}
              />
              <Text>
                Please release and prepare the pallet but DO NOT arrange
                shipping (FCA).
              </Text>
            </div>

            <>
              <div className="d-flex align-items-center m-3">
                <h2 style={{ paddingRight: '10px' }}> * </h2>
                <Checkbox
                  type="checkbox"
                  id="UK-3"
                  name="UK-3"
                  value="Paneer"
                  disabled={
                    infoUK[0].value === false && infoUK[1].value === false
                      ? false
                      : true
                  }
                  onChange={e => handleChangeUK(e.target.id)}
                />
                <Text>
                  Please arrange and invoice for shipping to my registered
                  address or address below (DAP).
                </Text>
              </div>
              <div className="d-flex flex-column align-items-start">
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <div>
                    <Text>Delivery Address</Text>
                    <Dropdown
                      list={addresses}
                      handleChange={selectedOption =>
                        handleTextDateUK(selectedOption.label, 'address')
                      }
                      placeholder="Select..."
                      isMulti={false}
                      isDisabled={newAddress}
                    />
                  </div>

                  <Button
                    style={{ width: '90px' }}
                    backgroundColor={theme.palette.primary.main}
                    type="button"
                    onClick={addNewAddress}
                    disabled={infoUK[2].value === false ? true : false}
                    value={newAddress ? 'BACK' : 'ADD'}
                  />
                </div>

                {newAddress && (
                  <ShortInput
                    id="Aditional"
                    style={{ marginBottom: '10px', marginTop: '30px' }}
                    onBlur={e => handleTextDateUK(e.target.value, 'address')}
                  />
                )}
                <div className="d-flex align-items-center m-3">
                  <Checkbox
                    type="radio"
                    id="asap"
                    name="when"
                    value="asap"
                    disabled={infoUK[2].value === false ? true : false}
                    onChange={e => handleChangeUK(e.target.id)}
                  />
                  <Text>ASAP</Text>
                </div>
                <div className="d-flex align-items-center m-3">
                  <Checkbox
                    type="radio"
                    id="day"
                    name="when"
                    value="day"
                    disabled={infoUK[2].value === false ? true : false}
                    onChange={e => handleChangeUK(e.target.id)}
                  />
                  <Text>Specified day</Text>
                </div>
                <div className="d-flex align-items-center m-3">
                  <Checkbox
                    type="checkbox"
                    id="lift"
                    name="need"
                    value="lift"
                    disabled={infoUK[2].value === false ? true : false}
                    onChange={e => handleChangeUK(e.target.id)}
                  />
                  <Text>I need a tail lift.</Text>
                </div>
              </div>
            </>

            <>
              <div className="d-flex align-items-center m-3">
                <Checkbox
                  type="checkbox"
                  id="UK-4"
                  disabled={infoUK[0].value === false ? false : true}
                  name="UK-4"
                  value="Paneer"
                  onChange={e => handleChangeUK(e.target.id)}
                />
                <Text>
                  This order will be consolidated with another/other releases
                  from other suppliers.
                </Text>
              </div>

              <div>
                <Text>
                  Please provide details of consolidations and volumes in the
                  text box.
                </Text>
                <Input
                  disabled={infoUK[3].value === false ? true : false}
                  onBlur={e => handleTextDateUK(e.target.value, 'UK-4')}
                  style={{ margin: '10px' }}
                />
              </div>
            </>

            <h4 className="mt-5">{t(translations.form.esp_instructions)}</h4>
            <h4>{t(translations.form.info)}</h4>
            <Input
              id="Aditional"
              style={{ marginBottom: '10px' }}
              onBlur={e => handleTextDateUK(e.target.value, e.target.id)}
            />
          </Inside>
        ) : null}
        <DateContainer>
          <h2>{t(translations.form.when)} *</h2>
          <input
            style={{}}
            name="Date"
            type="date"
            className="form-control"
            min={twoDaysLater}
            onChange={
              userData[0].importer_country === 'United Kingdom'
                ? e => handleTextDateUK(e.target.value, 'Date')
                : e => handleTextDate(e.target.value, 'Date')
            }
          />
          <h5 style={{ color: 'red', padding: '20px' }}>
            {t(translations.form.detail)}
          </h5>
        </DateContainer>
      </Container>
    </>
  );
}
